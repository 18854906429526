<template>
  <div>
    <div v-if="isDesktop" id="nav-bar" :class="{ 'nav-bar-hidden': isSidebarHidden }">
      <div id="nav-content">
        <img src="/Sustain-SBI.svg" alt="SBI Logo" class="logo" />
        <img src="/Data Centre.svg" alt="Data Centre" class="logo-Data-Centre" />
        <ul>
          <li v-for="(item, index) in items" :key="index" class="nav-button" @click="navigateTo(index, $event)" :class="{ selected: selectedItem === index }">
            <NuxtLink :to="item.route" class="nav-link">
              <svg :class="item.class" class="pr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path :d="item.icon" />
              </svg>
              <span>{{ item.title }}</span>
            </NuxtLink>
          </li>
        </ul>
        <div id="nav-content-highlight" :style="{ top: highlightPosition }"></div>
        <div class="nav-logout">
          <v-btn block @click="onSubmit" color="red" prepend-icon="mdi-logout"> Logout </v-btn>
        </div>
      </div>
    </div>
    <div v-else>
      <button id="nav-toggle" @click="toggleSidebar">☰</button>
      <div :class="['mobile-sidebar', { 'mobile-sidebar-hidden': isSidebarHidden }]" @click.self="closeSidebar">
        <ul>
          <li v-for="(item, index) in items" :key="index" class="nav-button" @click="navigateTo(index, $event)" :class="{ selected: selectedItem === index }">
            <NuxtLink :to="item.route" class="nav-link">
              <svg :class="item.class" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path :d="item.icon" />
              </svg>
              <span>{{ item.title }}</span>
              <svg :class="item.class" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path :d="item.icon" />
              </svg>
              <span>{{ item.title }}</span>
            </NuxtLink>
          </li>
        </ul>
        <div id="mobile-nav-content-highlight" :style="{ top: highlightPosition }"></div>
        <div>
          <v-btn block @click="onSubmit" color="red" prepend-icon="mdi-logout"> Logout </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mdiViewDashboard, mdiAlert, mdiCog } from "@mdi/js";
  import anime from "animejs/lib/anime.es.js";
  import { ref, onMounted, watch, nextTick } from "vue";
  import { useRouter, useRoute } from "vue-router";

  export default {
    setup() {
      const router = useRouter();
      const route = useRoute();
      const token = useCookie("token");

      const items = [
        { title: "Dashboard", route: "/dashboard", icon: mdiViewDashboard, class: "icon-dashboard" },
        { title: "Alerts", route: "/alerts", icon: mdiAlert, class: "icon-alert" },
        { title: "Configuration", route: "/configuration", icon: mdiCog, class: "icon-configuration" },
      ];

      const selectedItem = ref(-1);
      const highlightPosition = ref("0px");
      const isSmallScreen = ref(false);
      const isSidebarHidden = ref(false);
      const isDesktop = ref(true);

      const checkScreenSize = () => {
        isSmallScreen.value = window.innerWidth <= 600;
        isDesktop.value = window.innerWidth > 600;
      };

      const navigateTo = (index) => {
        router.push(items[index].route).then(() => {
          nextTick(() => {
            animateSelection(index);
          });
        });
      };

      const animateSelection = (index) => {
        selectedItem.value = index;
        localStorage.setItem("selectedItem", index);

        nextTick(() => {
          const element = document.querySelectorAll(".nav-button")[index];
          if (element) {
            const topPosition = element.offsetTop + "px";
            highlightPosition.value = topPosition;

            const highlightTarget = isSmallScreen.value ? "#mobile-nav-content-highlight" : "#nav-content-highlight";

            anime({
              targets: highlightTarget,
              top: topPosition,
              easing: "easeInOutQuad",
              duration: 200,
            });

            if (isSmallScreen.value) {
              closeSidebar();
            }
          }
        });
      };

      const updateHighlight = () => {
        const currentRoute = route.path;
        // console.log("Current Route:", currentRoute);

        const index = items.findIndex((item) => {
          if (item.route === "/") {
            return currentRoute === item.route;
          } else {
            return currentRoute.startsWith(item.route);
          }
        });

        // console.log("Matching Index:", index);

        if (index !== -1) {
          selectedItem.value = index;
          nextTick(() => {
            const navButtons = document.querySelectorAll(".nav-button");
            if (navButtons.length > 0) {
              const selectedButton = navButtons[selectedItem.value];
              if (selectedButton) {
                const topPosition = selectedButton.offsetTop + "px";
                highlightPosition.value = topPosition;
                const highlightTarget = isSmallScreen.value ? "#mobile-nav-content-highlight" : "#nav-content-highlight";
                anime.set(highlightTarget, {
                  top: topPosition,
                });
              }
            }
          });
        } else {
          selectedItem.value = -1;
        }
      };

      const handleResize = () => {
        checkScreenSize();
        updateHighlight();
      };

      const toggleSidebar = () => {
        isSidebarHidden.value = !isSidebarHidden.value;
        localStorage.setItem("isSidebarHidden", isSidebarHidden.value);
      };

      const closeSidebar = () => {
        isSidebarHidden.value = true;
        localStorage.setItem("isSidebarHidden", true);
      };

      async function onSubmit() {
        try {
          await $fetch("/api/v1/user/auth/logout", {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });
          router.push("/login");
        } catch (error) {
          // TODO: display error message
        }
      }

      onMounted(() => {
        checkScreenSize();
        window.addEventListener("resize", handleResize);

        updateHighlight();

        const savedSidebarState = localStorage.getItem("isSidebarHidden");
        if (savedSidebarState !== null) {
          isSidebarHidden.value = JSON.parse(savedSidebarState);
        } else {
          isSidebarHidden.value = window.innerWidth <= 600;
        }
      });

      watch(() => route.path, updateHighlight);

      return {
        items,
        selectedItem,
        highlightPosition,
        isSmallScreen,
        isSidebarHidden,
        animateSelection,
        toggleSidebar,
        isDesktop,
        closeSidebar,
        navigateTo,
        onSubmit,
      };
    },
  };
</script>

<style scoped>
  @import "../assets/sidebar.css";
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
  .nav-logout {
    margin-top: 220px;
    padding: 20px;
    display: flex;
    justify-content: center;
  }

  .nav-logout .v-btn {
    font-family: "Nunito Sans";
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    /* color: #808b95; */
    transform: scale(0.75);
  }
</style>
